import React from 'react';

import Logo from '../../../assets/images/BMLogo.png';

const Banner = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-16">
      <img alt="burgermark-logo" src={Logo} className="h-48" />
    </div>
  );
};

export { Banner };
