import React from 'react';
import Logo from '../../../assets/images/BMLogo.png';

export const GuestTitleComponent = ({ t }) => (
  <div className="flex items-center h-full gap-4">
    <div>
      <img alt="burgermark-logo-loyalty" src={Logo} className="h-28" />
    </div>
    <p data-test="loyalty-banner-title" className="loyalty-banner-text-wrapper-title">
      {t('loyaltyBannerTitle')}
    </p>
  </div>
);
